import {
  handleQueryResolve,
  buildMapToField
} from '../utils';

export default function (PatientID) {
  // classic JS with hoist and scope
  // FIXME break those function to their own modules and call them with this[query]
  const _vm = this;

  return Promise.all([
    queryPatientDoctor(PatientID)
  ]).then(patientRecords => {
    return patientRecords.reduce((patientData, patientRecord) => {
      return {
        ...patientData,
        ...patientRecord
      }
    }, {});
  });

  // Primary Provider is the top doctor
  function queryPatientDoctor(PatientID) {
    return _vm.query(`
            SELECT
            doctor.DoctorID,
            doctor.FirstName as DoctorFirstName,
            doctor.LastName as DoctorLastName,
            doctor.EMail as DoctorEmail,
            doctor.ID as CPSO,
            DoctorPhone.Phone as DoctorPhone,
            DoctorFax.Phone as DoctorFax,
            DoctorAddress.Address as DoctorAddress,
            DoctorAddress.City as DoctorCity,
            DoctorAddress.Province as DoctorProvince,
            DoctorAddress.Postal as DoctorPostal,
            COUNT(doctor.DoctorID) as RXperProvider
            FROM
            Fillware.dbo.Rx as rx
            INNER JOIN
            Fillware.dbo.Doctor as doctor ON doctor.DoctorID = rx.DoctorID
            INNER JOIN
            Fillware.dbo.DoctorAddress as DoctorAddress ON doctor.DoctorID = DoctorAddress.DoctorID AND DoctorAddress.DefaultAddress = 1
            INNER JOIN
            Fillware.dbo.RxExtended as RxExtended on RxExtended.RxNumber = Rx.RxNumber
            LEFT JOIN
            Fillware.dbo.DoctorPhone as DoctorFax ON DoctorFax.AddressID = RxExtended.DoctorAddressID AND DoctorFax.PhoneType = 'FAX'
            LEFT JOIN
            Fillware.dbo.DoctorPhone as DoctorPhone ON DoctorPhone.AddressID = RxExtended.DoctorAddressID AND DoctorPhone.PhoneType = 'BUSINESS'
            WHERE
            rx.RxDate > DATEADD(year, -1, GetDate()) AND
            rx.PatientID = @PatientID
            GROUP BY
            doctor.DoctorID,
            doctor.FirstName,
            doctor.LastName,
            doctor.EMail,
            doctor.ID,
            DoctorFax.Phone,
            DoctorPhone.Phone,
            DoctorAddress.Address,
            DoctorAddress.City,
            DoctorAddress.Province,
            DoctorAddress.Postal
            ORDER BY RXperProvider DESC
        `, [{
        param: 'PatientID',
        type: 'sql.Int',
        value: PatientID
      }])
      .then(handleQueryResolve)
      .then((recordset) => {
        if (recordset.length) {
          return buildMapToField(recordset[0]);
        }

        return {};
      });
  }
}